import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import "../styles/landingpage_contandostories.scss";
import "../styles/landing_analiseperfil.css";
import { MercadoPagoButton } from "../component/mpbutton.js";
import emailjs from "@emailjs/browser";

export const Landing_Contandostories = () => {
  const [paymodal, SetPaymodal] = useState(false);
  const sizerem = useRef();
  const sizeremcurso = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useRef();
  const [dataform, SetDataform] = useState({
    user_name: "",
    user_email: "",
    user_celular: "",
    user_pais: "",
    user_redesocial: "",
  });
  const [alertmessage, SetAlertMessage] = useState(false);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 20)
      : (sizerem.current = 20 + (5 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 16)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 12)
      : (sizeremcurso.current = 12 + (4 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
    // window.location.reload();
  };

  calcsizeimg();
  calcsizeimgcurso();

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm(
        "contactme@kikapeppermint",
        "template_1miu8vu",
        form.current,
        "YYH8R-60Dx8z_9NQI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    SetPaymodal(!paymodal);
    console.log("teste");
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#db2548" }}>
        <div
          className="text-white d-flex flex-lg-wrap justify-content-center pt-5 pb-5"
          style={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className=""
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />
                <Link to="/"
        style={{ textDecoration: "none" }}
        >
                <div
                  className="p-2 mt-2"
                  style={{ fontFamily: "Shorelines", color: "#db2548" }}
                  id="title"
                >
                  [kika PeppeminT]
                </div>
                </Link>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <div>
            <img
              src="contandostories_foto.png"
              style={{ width: sizeremcurso.current }}
            />
          </div>

          <h6 className="container mb-4 mt-4 text-white" id="sizeabletext">
            Ao criar um storytelling atraente e envolvente você gera mais
            engajamento com seus seguidores e aumenta a conexão, engajamento e
            conversão em vendas.
          </h6>

          <div className="">
            {/* <img src="kikapeppermint_photo.jpeg" alt="" /> */}

            <img
              src="squarekika.jpg"
              alt=""
              style={{ width: sizerem.current, borderRadius: "50%" }}
              id="peppermint"
            />
          </div>
          <h4 className="container mb-4 mt-4" id="sizeabletext">
            COM A CONSULTORIA "CONTANDO STORIES" VOCÊ TRANSFORMARÁ A MANEIRA DE
            FAZER SEUS STORIES PARA QUE RETER A ATENÇÃO DA SUA AUDIÊNCIA{" "}
            {/* <font className="fw-bold ">
              UMA IMAGEM FORTE E POSITIVA NAS SUAS REDES SOCIAIS.
            </font> */}
          </h4>

          <div className="container text-center">
            <button
              type="button"
              className="btn btn-lg mt-4 text-white"
              style={{
                backgroundColor: "#DB9E25",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
              }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              CLIQUE AQUI E COMPRE AGORA
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {paymodal === false ? (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex  flex-lg-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className=""
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <h6
                  className="modal-title mb-4"
                  id="exampleModalLabel"
                  style={{ textAlign: "justify" }}
                >
                  Antes de prosseguir precisamos de algumas informações de
                  contato. Após o preenchimento clique em "Seguir para
                  Pagamento".
                </h6>
                {alertmessage === true ? (
                  <h6
                    className="text-center"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    Você precisa preencher todos os dados antes de seguir para
                    pagamento!
                  </h6>
                ) : (
                  ""
                )}
                <div className="">
                  <form
                    className="container-fluid"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        Nome:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nome"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="user_name"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_name: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        Celular:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Celular"
                        aria-label="Celular"
                        aria-describedby="basic-addon1"
                        name="user_celular"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_celular: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        e-mail:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="e-mail"
                        aria-label="Useremail"
                        aria-describedby="basic-addon1"
                        name="user_email"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_email: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        País:
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="País"
                        aria-label="Userpais"
                        aria-describedby="basic-addon1"
                        name="user_pais"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_pais: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <span
                        className="input-group-text text-white"
                        id="basic-addon1"
                        style={{ width: "130px", backgroundColor: "#db2548" }}
                      >
                        @ Rede Social
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="@ da sua Rede Social"
                        aria-label="Userredesocial"
                        aria-describedby="basic-addon1"
                        name="user_redesocial"
                        onChange={(e) => {
                          SetDataform({
                            ...dataform,
                            user_redesocial: e.target.value,
                          });
                          let value = e.target.value;
                        }}
                      />
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn text-white p-2"
                        data-bs-dismiss="modal"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          SetAlertMessage(false);
                        }}
                      >
                        Fechar
                      </button>
                      <button
                        type="button"
                        className="btn text-white p-2"
                        style={{ backgroundColor: "#db2548" }}
                        onClick={() => {
                          console.log(dataform);
                          if (
                            dataform.user_redesocial !== "" &&
                            dataform.user_pais !== "" &&
                            dataform.user_email !== "" &&
                            dataform.user_celular !== "" &&
                            dataform.user_name !== ""
                          ) {
                            console.log("pode enviar");
                            sendEmail();
                          } else {
                            console.log("NÃO pode enviar");
                            SetAlertMessage(true);
                          }
                        }}
                      >
                        Seguir para Pagamento
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div>
                <button
                  type="button"
                  className="btn-close float-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    SetPaymodal(!paymodal);
                    console.log("fechar");
                  }}
                ></button>
              </div>
              <div className="modal-header justify-content-center">
                <div className="row" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="text-white d-flex flex-lg-wrap justify-content-center"
                    style={{
                      backgroundColor: "#fff",
                      borderBottomLeftRadius: "10%",
                      borderBottomRightRadius: "10%",
                    }}
                  >
                    <div className="text-center justify-content-center">
                      <h1 className="display-3 fst-italic mb-1">
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className=""
                            src="unnamed2.png"
                            id="peppermintcandy2"
                            alt="certified logo"
                            style={{ width: "2rem" }}
                          />
                          <div
                            className="p-2 mt-2"
                            style={{
                              fontFamily: "Shorelines",
                              color: "#db2548",
                            }}
                            id="title2"
                          >
                            [kika PeppeminT]
                          </div>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <h6
                  className="modal-title mb-4 text-center"
                  id="exampleModalLabel"
                >
                  Faça o pagamento abaixo pelo Mercado Pago ou por Cartão de
                  Crédito clicando no botão "Pagar".
                </h6>
                <div className="">
                  <div
                    className="text-center mt-1 mb-1 p-3 rounded text-white"
                    style={{ backgroundColor: "#db2548" }}
                  >
                    <h3
                      className="rounded"
                      style={{
                        backgroundColor: "#fff",
                        color: "#db2548",
                        fontWeight: "bold",
                      }}
                    >
                      Consultoria Contando Stories
                    </h3>

                    <h3
                      className="rounded"
                      style={{
                        backgroundColor: "#fff",
                        fontWeight: "bold",
                        color: "#db2548",
                      }}
                    >
                      R$ 157,00
                    </h3>
                    <span>
                      Esse valor pode ser parcelado com juros do cartão
                    </span>
                  </div>

                  <div className="modal-footer justify-content-center">
                    <button
                      type="button"
                      className="btn text-white"
                      style={{ backgroundColor: "#db2548" }}
                      onClick={() => {
                        SetPaymodal(!paymodal);
                        console.log("teste");
                      }}
                      data-bs-dismiss="modal"
                    >
                      Sair
                    </button>
                    <div>
                      <MercadoPagoButton
                        src={
                          "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js"
                        }
                        preferenceId={
                          "1338322951-398bfdd4-0b0d-4169-bc75-179663affb37"
                        }
                        source={"button"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex row flex-lg-wrap justify-content-center">
        <div className="d-flex justify-content-center mb-3 mt-4">
          <div className="container col-lg-8 ">
            <div className="p-1 text-center">
              <h4
                className="container p-2"
                id="sizeabletext3"
                style={{ color: "#db2548" }}
              >
                Nessa consultoria, você vai aprender a utilizar todas as
                ferramentas disponíveis dos stories de forma mais intencional e
                estética, para criar “histórias” que atraiam e retenham a
                atenção do seu público.{" "}
              </h4>
              <h4
                className="container p-2"
                id="sizeabletext3"
                style={{ color: "#db2548" }}
              >
                Você aprenderá técnicas para criar um storytelling envolvente,
                visualmente mais intencional para chamar mais atenção do seu
                público, com uma narrativa que conduz para um objetivo final, e
                consequentemente vender mais. {" "}
              </h4>
              
            </div>
          </div>
        </div>

        <h1 className="p-2 mt-2 display-5 text-center">
          <b className="" style={{ color: "#db2548" }}>
            {" "}
            &nbsp; Pra quem é essa a Cosultoria Contando Stories?
          </b>
        </h1>

        <div className="container justify-content-center gap-3 p-2">
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="bi bi-caret-right-fill me-2"
                    style={{ color: "#DB9E25" }}
                  ></i>
                  <p id="sizeabletext3" style={{ textAlign: "justify" }}>
                    Se você não sabe o que postar nos stories.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text ">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item "
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="bi bi-caret-right-fill me-2"
                    style={{ color: "#DB9E25" }}
                  ></i>
                  <p id="sizeabletext3" style={{ textAlign: "justify" }}>
                    Se você quer criar stories mais estéticos e que geram mais
                    interações.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item "
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="tbi bi-caret-right-fill me-2"
                    style={{ color: "#DB9E25" }}
                  ></i>
                  <p
                    className="float-start"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Se você deseja prender a atenção dos seus seguidores com um
                    storytelling mais atraente e envolvente.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item"
                  style={{ color: "#db2548", fontSize: "28px" }}
                >
                  <i
                    className="bi bi-caret-right-fill me-2 align-self-stretch"
                    style={{ color: "#DB9E25" }}
                  ></i>
                  <p
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Se você quer aumentar o nível de consciência e conexão da
                    transformação que sua marca promove.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2">
        <img
          src="mockup1.jpg"
          alt=""
          id="peppermint"
          style={{ width: sizerem.current }}
        />
      </div>

      <div
        className="d-flex row flex-lg-wrap justify-content-center mt-1"
        style={{ backgroundColor: "#fff" }}
      >
        <h1 className="p-2 text-center mt-2">
          <span className="" style={{ color: "#db2548" }}>
            {" "}
            Vantagens na compra da Consultoria Contando Stories:
          </span>
        </h1>

        <div className="container justify-content-center gap-3 p-2">
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <i
                    className="bi bi-pencil-square me-2 text-white"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Minha consultoria é adaptada às suas necessidades e
                    objetivos, de forma que você possa desenvolver sua própria
                    estratégia de storytelling que gere os resultados que você
                    espera.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <i
                    className="bi bi-film me-2 text-white"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Você vai aumentar sua conexão e engajamento com sua
                    audiência nas redes sociais, adquirir mais confiança na
                    criação dessas histórias e vender mais.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <i
                    className="bi bi-images me-2 text-white"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Você vai aprender a criar imagens de forma mais estética
                    especificamente dentro dessa ferramenta das redes sociais.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <i
                    className="bi bi-chat me-2 text-white"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Irá aprender como utilizar os recursos dos stories de forma
                    criativa, de acordo com a intenção proposta.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="container col-lg-6 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto ">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <i
                    className="bi bi-alarm me-2 text-white"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto", textAlign: "justify" }}
                  >
                    Você vai aprender como otimizar o seu tempo, reciclando material visual antigo, sem
                    parecer repetitiva.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container d-flex justify-content-center gap-1 row flex-wrap flex-lg-row mt-2 mb-2">
          <div className="col-md-2 card border-0 justify-content-center">
            <div className="card-text align-middle">
              <ul
                className="list-group list-group-flush mx-auto rounded"
                style={{ backgroundColor: "#db2548" }}
              >
                <i
                  className="bi bi-clock-fill text-center mt-2"
                  style={{ color: "#fff", fontSize: "2rem" }}
                ></i>
                <li
                  className="list-group-item text-center text-white "
                  style={{ backgroundColor: "#db2548", fontWeight: "bold" }}
                >
                  Realização de reunião com 1h de duração
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 card border-0 justify-content-center">
            <div className="card-text align-middle">
              <ul
                className="list-group list-group-flush mx-auto rounded"
                style={{ backgroundColor: "#db2548" }}
              >
                <i
                  className="bi bi-file-text-fill text-center mt-2"
                  style={{ color: "#fff", fontSize: "2rem" }}
                ></i>
                <li
                  className="list-group-item text-center text-white"
                  style={{ backgroundColor: "#db2548", fontWeight: "bold" }}
                >
                  Envio de uma guia, em .pdf, com os assuntos abordados na
                  consulta.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 card border-0 justify-content-center">
            <div className="card-text align-middle">
              <ul
                className="list-group list-group-flush mx-auto rounded"
                style={{ backgroundColor: "#db2548" }}
              >
                <i
                  className="bi bi-person-video text-center mt-2"
                  style={{ color: "#fff", fontSize: "2rem" }}
                ></i>
                <li
                  className="list-group-item text-center text-white"
                  style={{ backgroundColor: "#db2548", fontWeight: "bold" }}
                >
                  Reunião online através de vídeo chamada
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-auto mt-1 mb-2">
          <h1 className="p-2 text-center mt-4">
            <span className="" style={{ color: "#db2548" }}>
              {" "}
              Tudo isso por apenas:
            </span>
          </h1>
          <div
            className="text-center mt-1 mb-4 p-3 rounded text-white"
            style={{ backgroundColor: "#db2548" }}
          >
            <h3
              className="rounded"
              style={{
                backgroundColor: "#fff",
                color: "#db2548",
                fontWeight: "bold",
              }}
            >
              Valor Promocional de R$ 157
            </h3>
            <p>Esse valor pode ser parcelado com juros do cartão</p>

            <button
              type="button"
              className="btn btn-lg mt-2 text-white mb-2"
              style={{
                backgroundColor: "#DB9E25",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
              }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              CLIQUE AQUI PARA COMPRAR AGORA
            </button>
          </div>
        </div>
      </div>

      <div
        className="text-white d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div className="text-center justify-content-center mb-4 mt-4">
          <h4 className="container mb-4 mt-4" id="sizeabletext">
            OLÁ, SOU KIKA PEPPERMINT
          </h4>

          <div className="">
            {/* <img src="kikapeppermint_photo.jpeg" alt="" /> */}

            <img
              src="quemsoueulp2.jpg"
              alt=""
              style={{ width: sizerem.current, borderRadius: "50%" }}
              id="peppermint"
            />
          </div>

          <h6 className="container mb-2 mt-4 text-white" id="sizeabletext">
            Sou graduada em produção cultural, fotógrafa de ensaios femininos e
            de família, tenho formação em cinema e linguagem audiovisual e
            produzo conteúdo sobre fotografia, selfies e vídeos com o objetivo
            de ajudar mulheres a produzir seu próprio material visual de forma
            atrativa e sem depender de ninguém.
          </h6>
        </div>
      </div>
      <div
        className="d-flex row flex-lg-wrap justify-content-center"
        style={{ backgroundColor: "#db2548" }}
      >
        <div
          className="container col-lg-8 border rounded-4"
          style={{ backgroundColor: "#fff" }}
        >
          <h1 className="m-5 display-5 text-center">
            <b className="" style={{ color: "#db2548" }}>
              {" "}
              {/* &nbsp; O que diz quem já passou por essa experiência! &nbsp; */}
              O que as pessoas tem achado sobre meu conteúdo!
            </b>
          </h1>

          <div className="container justify-content-center">
            <div
              className="d-flex row justify-content-center mb-4"
              id="containerteste"
            >
              {[
                <img src="depgenerico8.png" alt="" />,
                <img src="depcontandostories3.png" alt="" />,
                <img src="depcontandostories2.png" alt="" />,
                <img src="depgenerico7.png" alt="" />,
              ].map((num, index) => (
                <div
                  key={index}
                  className={`item ${
                    activeIndex === index ? "active rounded" : "rounded"
                  }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  {num}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container text-center mb-4">
        <button
            type="button"
            className="btn btn-lg mt-4 text-white"
            style={{
              backgroundColor: "#DB9E25",
              fontWeight: "bold",
              textShadow: "2px 2px 4px #000",
            }}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            CLIQUE AQUI E COMPRE AGORA - com valor
            promocional de R$ 157,00 
          </button>
          <p className="text-white">(o pagamento pode ser parcelado)</p>
        </div>
      </div>

      <div className="container d-flex flex-wrap justify-content-center">
        <div className="p-4 d-flex">
          <img
            className=""
            src="unnamed222.jpg"
            alt=""
            style={{
              width: "17rem",
              borderRadius: "50%",
              margin: "auto",
              borderRadius: "50%",
            }}
            id="peppermint"
          />
        </div>

        <div className="p-0 " style={{ margin: "auto" }}>
          <h5 className="text-center">
            Caso você tenha alguma dúvida sobre a Consultoria de Análise de
            Perfil mande um email para
          </h5>

          <h5 className="text-center p-0 mb-4">
            <font className="text-center p-0" style={{ fontWeight: "bold" }}>
              <a
                href="mailto:contactme@kikapeppermint.com"
                style={{ textDecoration: "none", color: "#db2548" }}
              >
                contactme@kikapeppermint.com
              </a>
            </font>
          </h5>
        </div>
      </div>

      <footer
        className="container footer stick-bottom d-flex flex-wrap justify-content-between align-items-center rounded mb-1"
        id="#footer"
        style={{ bottom: "0", backgroundColor: "#db2548" }}
      >
        <div className="col-md-5 d-flex text-white ">
          <span className="">© 2023 Kika Peppermint</span>
        </div>

        <div className="col-md-5 d-flex justify-content-end">
          <a
            href="https://www.instagram.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-instagram me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.facebook.com/StudioPepperMint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-facebook me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://br.pinterest.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-pinterest me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.tiktok.com/@kikapeppermint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-tiktok me-1"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};
