import React, { useState, useEffect } from 'react';
import "../styles/Carousel.css"

export const Carousel = () => {
  const images = [
    'doityourselfie21.jpg',
    'IMG_3345.jpg',
    'doityourselfie22.jpg',
    'FullSizeRender.jpg',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`carousel-image-${index}`}
          className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};



