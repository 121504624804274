import React, { useEffect, useRef, useState } from "react";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import ".././styles/lojinhadakika.css";	
import ReactCountryFlag from "react-country-flag";
import { StoreSelect } from "../component/selectionstore.js";

export const LojinhadaKika = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(false);

  const sizerem = useRef();
  const sizeremcurso = useRef();
  const ArrayOfProductsAmazonBrasil = [
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&marketplace=amazon&region=BR&placement=B07QG13HFW&asins=B07QG13HFW&linkId=e56d95a34e36027875764993dcfbfea6&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B08SKNLYMD&asins=B08SKNLYMD&linkId=82df9c05f4f87670203612649a03d12a&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B08DLQSQWT&asins=B08DLQSQWT&linkId=64486bf83fbe10ccfc389ff5e3f10663&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B07PVZKRN6&asins=B07PVZKRN6&linkId=f2ab15ad994d247e07da98ed204ea18e&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B014GQOZ36&asins=B014GQOZ36&linkId=5481d76a95b62bbe7e1bfe5d7c95ec71&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B0BWJ8KLT1&asins=B0BWJ8KLT1&linkId=50c09a577d7651852bd1d37c323ae342&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B07YCV78RL&asins=B07YCV78RL&linkId=8368e5ef3f814ce49c14f06c07d9d87c&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B08C7249KP&asins=B08C7249KP&linkId=994556a39fe9a52aad6f0adb277980ea&show_border=true&link_opens_in_new_window=true",
    "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=lojinhadakika-20&language=pt_BR&marketplace=amazon&region=BR&placement=B07QMVQNQ7&asins=B07QMVQNQ7&linkId=308ccff67faa97f504b8f907c664b46f&show_border=true&link_opens_in_new_window=true",
    
  ]

  const ArrayOfProductsAmazonEurope = [
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08LKF36TN&linkId=dc5edaba26574d132c68422f0b3d1ea6",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08LV9PCND&linkId=2d6d57a498c0645695f0c06e5284887f",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B09JZFQ4PD&linkId=aec47497d71d790e67d7081e6d8c5e1d",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08GFD9JB7&linkId=bc59ade9864d45a2967066efa5386eb0",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B00PJSIIES&linkId=2b9c8f98b0fedf23cbe29a8acbd51bb8",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B0925DSFVS&linkId=09ae271d1217a08a893454c80ce90f2a",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B08VN2V9M7&linkId=c7112cb52a1f9f74896528d9127e4d62",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B086ZM44D5&linkId=cd7e57a18c540505a0018ae6fccfb540",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B09XJT7FP8&linkId=9f5bf2180bab2930e1ea6c7188cae649",
    "//rcm-eu.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=kikapeppermin-21&language=pt_PT&o=30&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=B06Y21DLWB&linkId=e35f46691864fcf335d302679386b551",

  ]

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 16)
      : (sizerem.current = 16 + (7 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "px";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 35)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 20)
      : (sizeremcurso.current = 20 + (15 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "px";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
    // window.location.reload();
  };

  calcsizeimg();
  calcsizeimgcurso();

  
  const ListOfAmazonProducts = selectedOption === "Europa"? ArrayOfProductsAmazonEurope.map((product, index) => (
    <div className="me-4 mb-4">
      <iframe
          title="Amazon_Products"
          sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
          style={{
            width: "120px",
            height: "240px",
            marginWidth: "0",
            marginHeight: "0",
            scrolling: "no",
            frameborder: "0",
          }}
          src={product}
        ></iframe>
    </div>
  )) : ArrayOfProductsAmazonBrasil.map((product, index) => (
    <div className="me-4 mb-4">
      <iframe
          title="Amazon_Products"
          sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
          style={{
            width: "120px",
            height: "240px",
            marginWidth: "0",
            marginHeight: "0",
            scrolling: "no",
            frameborder: "0",
          }}
          src={product}
        ></iframe>
    </div>
  ))

  function CustomSelectOption({ value, flagCode, label, onClick }) {
    return (
      <div onClick={() => onClick(value)}>
        <ReactCountryFlag
          countryCode={flagCode}
          svg
          style={{
            width: "2em",
            height: "2em",
            marginRight: "8px",
          }}
          title={flagCode}
        />
        {label}
      </div>
    );
  }
  
  function StoreSelect() {
    
  
    const handleOptionClick = (value) => {
      setSelectedOption(value);
      setOptionsVisible(false);
    };
  
    return (
      <div>
        <label htmlFor="store">Selecione a Localidade da Loja:</label>
        <div className="custom-select text-white rounded-1 p-1" style={{backgroundColor: "#db2548"}} onClick={() => setOptionsVisible(!optionsVisible)}>
          {selectedOption || "CLIQUE AQUI"}
          <div className={`custom-select-options ${optionsVisible ? "show" : ""}`}>
            <CustomSelectOption value="Brasil" flagCode="BR" label="Brasil" onClick={handleOptionClick} />
            <CustomSelectOption value="Europa" flagCode="EU" label="Europa" onClick={handleOptionClick} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid " >
      <div className="row bg-white" style={{ backgroundColor: "#db2548" }}>
        <div
          className="text-white d-flex flex-lg-wrap justify-content-center pt-4 pb-4 "
          style={{
            backgroundColor: "#db2548",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className=""
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />
                <div
                  className="p-2 mt-2 text-white"
                  style={{ fontFamily: "Shorelines" }}
                  id="title"
                >
                  [Lojinha da Kika]
                </div>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div className="" style={{ height: "30px" }}></div>

      <div className="row justify-content-center" >
        <span className="col-lg-9 mb-4 text-center" style={{fontSize: sizeremcurso.current, color: "#db2548"}}>VOCÊ ESTÁ NA LOJINHA DA KIKA</span>
  
        <div className="container col-lg-7 card border-0 justify-content-center mb-2">
            <div className="card-text">
              <ul className="list-group list-group-flush mx-auto">
                <li
                  className="d-flex list-group-item rounded"
                  style={{
                    color: "#db2548",
                    fontSize: "28px",
                    backgroundColor: "#db2548",
                  }}
                >
                  <i
                    className="bi bi-bag-heart me-2 text-white"
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <p
                    className="ms-3 text-white"
                    id="sizeabletext3"
                    style={{ margin: "auto" }}
                  >
                    Aqui você encontra acessórios e eletrônicos que eu gosto e que podem te ajudar a fazer Selfies e Vlogs.
                  </p>
                </li>
              </ul>
            </div>
          </div>
                  
                  <div className="container col-lg-7 mt-2">


<StoreSelect />


                  </div>

      </div>

      <div className="container col-lg-7" id="amazon">
      <h4 className="mt-4 mb-4" style={{fontSize: sizerem.current, color: "#db2548", backgroundColor: ""}}> {selectedOption === "Europa"? "Links para comprar na Amazon Espanha" : "Links para comprar na Amazon Brasil"} </h4>
      <div className="d-flex flex-wrap justify-content-center ">
          {ListOfAmazonProducts}
          
      </div>
      
        

        <div className="mt-4"></div>
      </div>
    </div>
  );
};
