import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import "../styles/landingpage_analiseperfil.scss";
import "../styles/landing_analiseperfil.css";
import { MercadoPagoButton } from "../component/mpbutton.js";
import emailjs from "@emailjs/browser";

export const Pag_Aprovado = () => {
  const [paymodal, SetPaymodal] = useState(false);
  const sizerem = useRef();
  const sizeremcurso = useRef();
  const [activeIndex, setActiveIndex] = useState(null);
  const form = useRef();
  const [dataform, SetDataform] = useState({
    user_name: "",
    user_email: "",
    user_celular: "",
    user_pais: "",
    user_redesocial: "",
  });
  const [alertmessage, SetAlertMessage] = useState(false);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 20)
      : (sizerem.current = 20 + (5 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 16)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 12)
      : (sizeremcurso.current = 12 + (4 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
    // window.location.reload();
  };

  calcsizeimg();
  calcsizeimgcurso();

  const sendEmail = (e) => {
    // e.preventDefault();

    emailjs
      .sendForm(
        "contactme@kikapeppermint",
        "template_1miu8vu",
        form.current,
        "YYH8R-60Dx8z_9NQI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    SetPaymodal(!paymodal);
    console.log("teste");
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{ backgroundColor: "#fff" }}>
        <div
          className="text-white d-flex flex-lg-wrap justify-content-center pt-5 pb-5"
          style={{
            backgroundColor: "#db2548",
            borderBottomLeftRadius: "10%",
            borderBottomRightRadius: "10%",
          }}
        >
          <div className="text-center justify-content-center">
            <h1 className="display-3 fst-italic mb-1">
              <div className="d-flex justify-content-center align-items-center">
                <img
                  className=""
                  src="unnamed2.png"
                  id="peppermintcandy"
                  alt="certified logo"
                />
                <div
                  className="p-2 mt-2 text-white"
                  style={{ fontFamily: "Shorelines" }}
                  id="title"
                >
                  [kika PeppeminT]
                </div>
              </div>
            </h1>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-wrap flex-md-nowrap justify-content-center">
        <div className="p-4 d-flex">
          <img
            className=""
            src="quemsoueulp1.jpg"
            alt=""
            style={{
              width: "17rem",
              borderRadius: "50%",
              margin: "auto",
              borderRadius: "50%",
            }}
            id="peppermint"
          />
        </div>

        <div className="p-0 " style={{ margin: "auto" }}>
          <h5 className="text-center">
            Seu Pagamento foi <b>processado com sucesso</b>. Você receberá a
            confirmação em um e-mail do Mercado Pago.
          </h5>

          <h5 className="text-center p-0 mb-4 mt-5">
            <font className="text-center p-0" style={{ fontWeight: "bold" }}>
              <a
                href="mailto:contactme@kikapeppermint.com"
                style={{ textDecoration: "none", color: "#db2548" }}
              >
                Em breve entrarei em contato para passar orientações sobre os proximos passos da nossa Consultoria.
              </a>
            </font>
          </h5>
          <div className="container text-center mb-4">
            <Link to="/">
            <button
              type="button"
              className="btn btn-lg mt-4 text-white"
              style={{
                backgroundColor: "#db2548",
                fontWeight: "bold",
                textShadow: "2px 2px 4px #000",
              }}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              CLIQUE AQUI PARA RETORNAR AO KIKA PEPPERMINT
            </button>
            </Link>
          </div>
        </div>
      </div>

      <footer
        className="container footer stick-bottom d-flex flex-wrap justify-content-between align-items-center rounded mb-1"
        id="#footer"
        style={{ bottom: "0", backgroundColor: "#db2548" }}
      >
        <div className="col-md-5 d-flex text-white ">
          <span className="">© 2023 Kika Peppermint</span>
        </div>

        <div className="col-md-5 d-flex justify-content-end">
          <a
            href="https://www.instagram.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-instagram me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.facebook.com/StudioPepperMint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-facebook me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://br.pinterest.com/kikapeppermint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-pinterest me-3"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
          <a
            href="https://www.tiktok.com/@kikapeppermint"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="bi bi-tiktok me-1"
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};
