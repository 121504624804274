import React, { useState, useRef } from "react";
import "../styles/landingpage.css";
// import "../styles/landingpagescss.scss"
import "../styles/landingpagescss2.scss";
import "../shorelines-script/Shorelines_Script_Bold.otf";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

export const Home = () => {
  const [showcontactme, setShowContactMe] = useState(true);
  const [showaboutme, setShowAboutMe] = useState(true);
  const [changenamebutton, setChangeNameButton] = useState(true);
  const [changenamebutton2, setChangeNameButton2] = useState(true);
  const [showconsultorias, SetShowConsultorias] = useState(false);
  const form = useRef();
  const sizerem = useRef();
  const sizeremcurso = useRef();
  const [activeIndex, setActiveIndex] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "contactme@kikapeppermint",
        "template_1miu8vu",
        form.current,
        "lbuZ2q-6hgq2KE01C"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.alert("Mensagem Enviada");
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const calcsizeimg = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizerem.current = 25)
      : window.innerWidth < 599
      ? (sizerem.current = 20)
      : (sizerem.current = 20 + (5 / 602) * (sizewindow - 599));
    sizerem.current = sizerem.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizerem);
  };

  const calcsizeimgcurso = () => {
    let sizewindow = window.innerWidth;

    window.innerWidth > 1200
      ? (sizeremcurso.current = 16)
      : window.innerWidth < 599
      ? (sizeremcurso.current = 12)
      : (sizeremcurso.current = 12 + (4 / 602) * (sizewindow - 599));
    sizeremcurso.current = sizeremcurso.current.toString() + "rem";
    console.log(sizewindow);
    console.log(sizeremcurso);
  };

  window.onresize = function () {
    calcsizeimg();
    calcsizeimgcurso();
    // window.location.reload();
  };

  calcsizeimg();
  calcsizeimgcurso();

  // const { ref } = useParallax({speed: 10});
  return (
    // <div ref={ref}>
    <div className="d-flex flex-column " style={{ height: "90vh" }}>
      <nav
        id="navbar_consultant "
        className="navbar navbar-expand-lg d-flex justify-content-center container-fluid mb-1"
        style={{
          backgroundColor: "#db2548",
          borderBottomLeftRadius: "20%",
          borderBottomRightRadius: "20%",
        }}
      >
       
        <div>
          <img
            className=""
            src="unnamed2.png"
            id="peppermintcandy"
            alt="certified logo"
          />
        </div>
        <Link to="/"
        style={{ textDecoration: "none" }}
        >
        <div className="p-2 mt-2 text-white">[kika PeppeminT]</div>
        </Link>
      </nav>
      <div
        className="d-flex flex-wrap justify-content-between align-items-center py-1 bg-white"
        id="#footer"
      >
        <div className="col-md-4 d-flex justify-content-center"></div>
        <div className="col-md-4 d-flex justify-content-center p-3">
          {showconsultorias === true ? (
            <button
              className="btn text-white"
              style={{ backgroundColor: "#db2548" }}
              onClick={() => {
                SetShowConsultorias(!showconsultorias);
              }}
            >
              Voltar para a Home
            </button>
          ) : (
            <div className="d-flex gap-2">
              <button
                className="btn text-white"
                style={{ backgroundColor: "#db2548" }}
                onClick={() => {
                  setShowAboutMe(!showaboutme);
                  setChangeNameButton2(!changenamebutton2);
                }}
              >
                {" "}
                {changenamebutton2 === true
                  ? "Sobre Mim"
                  : "Voltar a Pagina Principal"}{" "}
              </button>
              <button
                className="btn text-white"
                style={{ backgroundColor: "#db2548" }}
                onClick={() => {
                  setShowContactMe(!showcontactme);
                  setChangeNameButton(!changenamebutton);
                }}
              >
                {changenamebutton === true
                  ? "Fale Comigo"
                  : "Voltar a Pagina Principal"}
              </button>
              <a
                href="https://thepeppermintstudio.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#db2548" }}
                >
                  {" "}
                  Peppermint Studio{" "}
                </button>
              </a>
            </div>
          )}
        </div>

        <div className="col-md-4 d-flex justify-content-center"></div>
      </div>

      {showconsultorias === true ? (
        <div
          className="d-flex container justify-content-center"
          style={{ flex: "1" }}
        >
          <div
            className="d-flex row justify-content-center align-items-center"
            id="consultoriasdiv"
          >
            <div className="col d-flex justify-content-center">
              <div className="" id="">
                {/* <img src="kikapeppermint_photo.jpeg" alt="" /> */}

                <img
                  src="kikacamera.jpg"
                  alt=""
                  style={{ width: sizerem.current, borderRadius: "50%" }}
                  id="peppermint"
                />
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="" id="figure4">
                <h1 className="p-2 rounded">Análise de Perfil</h1>
                <p
                  className=""
                  id="anotherp"
                  style={{ fontSize: "15px", textAlign: "justify" }}
                >
                  Minha Análise de Perfil pelo ponto de vista visual e
                  humanizado, com estruturação de metas vai te dar consciência
                  sobre como tornar seu perfil mais atrativo e engajante.{" "}
                </p>

                <img src="" alt="" />
                <figcaption className="">
                  {/* <h3>
                            Saiba Mais
                          </h3> */}

                  <p>
                    Clique no botão para conhecer mais sobre a Consultoria de
                    Análise de Perfil.
                  </p>
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  <br className="mb-5"></br>

                  <Link
                    to="landing_analiseperfil"
                    style={{ textDecoration: "none" }}
                  >
                    <button>Clique aqui</button>
                  </Link>
        
                </figcaption>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="" id="figure4">
                <h1 className="p-2 rounded">Do It YourSelfie</h1>
                <p
                  id="anotherp"
                  style={{ fontSize: "15px", textAlign: "justify" }}
                >
                  Você sabia que você faz parte da sua marca, e que Suas selfies
                  e vlogs podem ser usados na sua criação de conteúdo e gerar
                  mais conexão com seus seguidores?
                </p>
                <img src="" alt="" />
                <figcaption>
                  {/* <h3>
                            Saiba Mais
                          </h3> */}
                          
                  <p>
                    Clique no botão para conhecer mais sobre a Consultoria de
                    "Do It YourSelfie".
                  </p>
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  <br className="mb-5"></br>

                  <Link
                    to="landing_doityourselfie"
                    style={{ textDecoration: "none" }}
                  >
                    <button>Clique aqui</button>
                  </Link>
              
          
                 
                </figcaption>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="" id="figure4">
                <h1 className="p-2 rounded">Contando Stories</h1>
                <p
                  id="anotherp"
                  style={{ fontSize: "15px", textAlign: "justify" }}
                >
                  Saber fazer um storytelling atraente e envolvente com certeza
                  gera mais engajamento com seus seguidores e aumenta a conexão
                  e conversão em vendas.
                </p>

                <img src="" alt="" />
                <figcaption>
                  {/* <h3>
                            Saiba Mais
                          </h3> */}

                  <p>
                    Clique Aqui para ver qual consultoria se adequa melhor às
                    suas necessidades.
                  </p>
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                  <br className="mb-5"></br>

                  <Link
                    to="landing_contandostories"
                    style={{ textDecoration: "none" }}
                  >
                    <button>Clique aqui</button>
                  </Link>
       
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex container justify-content-center"
          style={{ flex: "1" }}
        >
          {showcontactme === true && showaboutme === true ? (
            <div
              className="d-flex row justify-content-center align-items-center"
              id="mainpagediv"
            >
              <div className="col">
                <div className="d-flex justify-content-center" id="">
                  {/* <img src="kikapeppermint_photo.jpeg" alt="" /> */}

                  <img
                    className=""
                    src="squarekika.jpg"
                    alt=""
                    style={{ width: sizerem.current, borderRadius: "50%" }}
                    id=""
                  />
                </div>
              </div>
              <div className="col d-flex justify-content-center">
                <figure className="">
                  <h1 className="p-2 rounded">Consultorias</h1>
                  <p
                    className=""
                    id="anotherp"
                    style={{ fontSize: "15px", textAlign: "justify" }}
                  >
                    Uma comunicação visual estética e humanizada das redes
                    sociais gera mais engajamento e conexão com a audiência,
                    pensando nisso criei algumas consultorias para você
                    potencializar sua marca.{" "}
                  </p>

                  <img src="" alt="" />
                  <figcaption className="">
                    {/* <h3>
                            Saiba Mais
                          </h3> */}
                    <p>
                      Clique Aqui para ver qual consultoria se adequa melhor às
                      suas necessidades.
                    </p>
                    {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                    {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                    {/* CONSENTAR O ESPAÇAMENTO ABAIXO */}
                    <br className="mb-5"></br>

                    <button
                      onClick={() => {
                        SetShowConsultorias(!showconsultorias);
                      }}
                    >
                      Clique aqui
                    </button>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                  </figcaption>
                </figure>
              </div>

              <div className="col d-flex justify-content-center">
                <div className="" id="figure3">
                  <h1 className="p-2 rounded">Mentoria</h1>
                  <p
                    id="anotherp"
                    style={{ fontSize: "15px", textAlign: "justify" }}
                  >
                    Com a minha mentoria, você terá acesso a todos os itens
                    citados nas consultorias em um único pacote, durante um
                    período de 4 semanas com o objetivo de aprimorar ainda mais
                    a sua comunicação visual e gerar resultados ainda melhores.
                  </p>

                  <img src="" alt="" />
                  <figcaption>
                    {/* <h3>
                            Saiba Mais
                          </h3> */}
                    {/* <p>
                    Clique abaixo para ver mais detalhes da Aula de Fotografia e
                    se adequa às suas necessidades.
                  </p> */}
                    {/* <a
                    href="https://thepeppermintstudio.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  > */}
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <button>em Breve</button>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    {/* </a> */}
                  </figcaption>
                </div>
              </div>
              <div className="col d-flex justify-content-center">
                <div className="" id="figure3">
                  <h1 className="p-2 rounded">Fotografia</h1>
                  <p
                    id="anotherp"
                    style={{ fontSize: "15px", textAlign: "justify" }}
                  >
                    Todos nós queremos registrar os momentos especiais da vida,
                    como uma viagem inesquecível, momentos em família e de
                    diversão. Aprender fotografia pode te ajudar a capturar
                    esses momentos de uma forma especial e criar lembranças
                    duradouras.
                  </p>
                  <img src="" alt="" />
                  <figcaption>
                    {/* <h3>
                            Saiba Mais
                          </h3> */}
                    {/* <p>
                    Clique abaixo para ver mais detalhes da Aula de Fotografia e
                    se adequa às suas necessidades.
                  </p> */}
                    {/* <a
                    href="https://thepeppermintstudio.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  > */}
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <button>em Breve</button>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    <br className="mb-5"></br>
                    {/* </a> */}
                  </figcaption>
                </div>
              </div>
            </div>
          ) : showaboutme === false ? (
            <div
              className="d-flex row justify-content-center align-items-center"
              id="contactmediv"
            >
              <div className="col-sm-4 d-flex justify-content-center">
                <div className="" id="">
                  {/* <img src="kikapeppermint_photo.jpeg" alt="" /> */}

                  <img
                    src="kikadancing.jpg"
                    alt=""
                    style={{ width: sizerem.current, borderRadius: "50%" }}
                  />
                </div>
              </div>
              <div className="col align-items-center mt-2">
                <h5 className="text-start">
                  Olá, eu sou kika peppermint sou carioca, mãe e imigrante em
                  Portugal.
                </h5>
                <br></br>

                <p style={{ textAlign: "justify" }}>
                  Vivo o mundo das artes visuais desde que me entendo por gente.
                  Sou graduada em produção cultural, o que me proporcionou
                  aprender de forma muito próxima sobre todas as formas de
                  expressão artística e culturais. Porém, depois da graduação,
                  senti que faltava me aprofundar mais no mundo das imagens.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Me formei fotógrafa e me especializei em ensaios femininos e
                  de família, tenho formação em cinema e linguagem audiovisual.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Sempre amei criar memórias e sempre me preocupei em ter meu
                  acervo familiar de memórias sempre atualizado, porém em 2020,
                  durante a pandemia me vi sem a possibilidade de fotografar e
                  ser fotografada. Passei a fazer selfies (sozinha e de família)
                  para não perder os momentos e para ter memórias onde eu me
                  sentia bem representada.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Não demorou muito comecei a ensinar como fazer o mesmo e
                  passei a produzir conteúdo sobre selfies e vlogs.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Minha missão desde então é ajudar mulheres a produzir seu
                  próprio material visual de forma atrativa e sem depender de
                  ninguém. Vem comigo?
                </p>
              </div>
            </div>
          ) : (
            <div
              className="d-flex row justify-content-center align-items-center"
              id="contactmediv"
            >
              <div className="col-sm-4 d-flex justify-content-center">
                <div className="" id="">
                  {/* <img src="kikapeppermint_photo.jpeg" alt="" /> */}

                  <img
                    src="kikacontactme.jpg"
                    alt=""
                    style={{ width: sizerem.current, borderRadius: "50%" }}
                  />
                </div>
              </div>
              <div className="col align-items-center mt-2">
                <h5 className="text-center">
                  Olá, você pode me enviar mensagens através das redes sociais
                  clicando nos icones abaixo, ou ainda, encaminhar um email
                  preenchendo o formulário a seguir.
                </h5>
                <div className="col d-flex justify-content-center mb-4 mt-2">
                  <a
                    href="https://www.instagram.com/kikapeppermint/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-instagram me-3"
                      style={{ color: "#db2548", fontSize: "40px" }}
                    ></i>
                  </a>
                  <a
                    href="https://www.facebook.com/StudioPepperMint"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-facebook me-3"
                      style={{ color: "#db2548", fontSize: "40px" }}
                    ></i>
                  </a>
                  <a
                    href="https://br.pinterest.com/kikapeppermint/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-pinterest me-3"
                      style={{ color: "#db2548", fontSize: "40px" }}
                    ></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@kikapeppermint"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-tiktok"
                      style={{ color: "#db2548", fontSize: "40px" }}
                    ></i>
                  </a>
                </div>
                <h6 className="mb-2 text-center mb-2">
                  PREENCHA O FORMULÁRIO ABAIXO PARA ENVIAR UMA MENSAGEM
                </h6>
                <div className="col d-flex justify-content-center mb-4">
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="d-flex flex-column "
                    style={{ width: "310px" }}
                  >
                    <input
                      className="mb-2"
                      type="text"
                      name="user_name"
                      style={{ width: "300px" }}
                      placeholder="Nome"
                    ></input>
                    <input
                      className="mb-2"
                      type="text"
                      name="user_email"
                      style={{ width: "300px" }}
                      placeholder="E-mail"
                    ></input>
                    <select
                      className="mb-2"
                      type=""
                      name="user_subject"
                      style={{ width: "300px" }}
                    >
                      <option value="info">Informações Adicionais</option>
                      <option value="consultoria">Consultoria</option>
                      <option value="mentoria">Mentoria</option>
                      <option value="assessoria">Fotografia</option>
                    </select>
                    <textarea
                      className="mb-2"
                      name="message"
                      style={{
                        width: "300px",
                        height: "100px",
                        maxHeight: "200px",
                      }}
                      placeholder="Mensagem"
                    ></textarea>
                    <button
                      className="btn text-white"
                      style={{ backgroundColor: "#db2548", width: "300px" }}
                      onClick={sendEmail}
                    >
                      Enviar Mensagem
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="mt-2">
        <footer
          className="footer stick-bottom d-flex flex-wrap justify-content-between align-items-center bg-white"
          id="#footer"
          style={{ bottom: "0" }}
        >
          <div className="col-md-4 d-flex justify-content-center ms-2">
            <span className="" style={{ color: "#db2548" }}>
              © 2023 Kika Peppermint
            </span>
          </div>

          <div className="col-md-4 d-flex justify-content-center me-3">
            <a
              href="https://www.instagram.com/kikapeppermint/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="bi bi-instagram me-3"
                style={{ color: "#db2548", fontSize: "20px" }}
              ></i>
            </a>
            <a
              href="https://www.facebook.com/StudioPepperMint"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="bi bi-facebook me-3"
                style={{ color: "#db2548", fontSize: "20px" }}
              ></i>
            </a>
            <a
              href="https://br.pinterest.com/kikapeppermint/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="bi bi-pinterest me-3"
                style={{ color: "#db2548", fontSize: "20px" }}
              ></i>
            </a>
            <a
              href="https://www.tiktok.com/@kikapeppermint"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="bi bi-tiktok"
                style={{ color: "#db2548", fontSize: "20px" }}
              ></i>
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};
