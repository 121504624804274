import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const MercadoPagoButton = ({ src, preferenceId, source, color }) => {
    const containerRef = useRef();
  
    useEffect(() => {
      const script = document.createElement('script');
      script.src = src;
      script.setAttribute('data-preference-id', preferenceId);
      script.setAttribute('data-source', source);

  
      const container = containerRef.current;
      container.appendChild(script);
  
      return () => {
        container.removeChild(script);
      };
    }, [src, preferenceId, source]);
  
    return <div ref={containerRef}></div>;
  };
  
  MercadoPagoButton.propTypes = {
    src: PropTypes.string.isRequired,
    preferenceId: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,

  };
  
