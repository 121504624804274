import React from "react";
import {Outlet} from "react-router-dom";
import {Navbar} from "../component/navbar.js";

export const Layout = () => {
  return (
    <>

      <Outlet />
    </>
  );
};

