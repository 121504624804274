import React from 'react';
import { Carousel } from './carousel';


export const Service = () => {
  return (
    <>
<Carousel />
</>
    
  );
  }