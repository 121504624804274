import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";

function CustomSelectOption({ value, flagCode, label, onClick }) {
  return (
    <div onClick={() => onClick(value)}>
      <ReactCountryFlag
        countryCode={flagCode}
        svg
        style={{
          width: "2em",
          height: "2em",
          marginRight: "8px",
        }}
        title={flagCode}
      />
      {label}
    </div>
  );
}

export const StoreSelect = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(false);

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setOptionsVisible(false);
  };

  return (
    <div>
      <label htmlFor="store">Selecione a Localidade da Loja da Amazon:</label>
      <div className="custom-select text-white rounded p-2" style={{backgroundColor: "#db2548"}} onClick={() => setOptionsVisible(!optionsVisible)}>
        {selectedOption || "CLIQUE AQUI"}
        <div className={`custom-select-options ${optionsVisible ? "show" : ""}`}>
          <CustomSelectOption value="Brasil" flagCode="BR" label="Brasil" onClick={handleOptionClick} />
          <CustomSelectOption value="Europa" flagCode="EU" label="Europa" onClick={handleOptionClick} />
        </div>
      </div>
    </div>
  );
}


